import { alchemyPay } from '@/assets'
import TokenChainIcon from '@/pages/market/components/TokenChainIcon'
import { classNames } from '@/utils'
import { useEffect, useMemo, useState } from 'react'
import Countdown from './Countdown'
import Order from './Order'
import { Checkbox } from 'antd-mobile'
import Tip from '@/components/Tip'
import { Routers } from '@/router'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getAccessToken, getCurrencyList, getQuote } from '@/api/ramp'
import useUserInfo from '@/stores/userStore/hooks/useUserStore'
import rampStore from '@/stores/rampStore'
import {
  CurrencyItem,
  IQuoteParams,
  QuoteRes,
  QuoteResponse,
  QuoteSide,
  SupportPayWay
} from '@/api/ramp/type'
import { observer } from 'mobx-react-lite'
import { getChainByToken } from '@/stores/walletStore/utils'
import { isEmpty } from '@/utils/helper'
import chains, {
  marketChain,
  onRampChain
} from '@/proviers/web3Provider/chains'
import { debounce } from '@/utils/debounce'
import BigNumber from 'bignumber.js'
import { LoadingIcon } from '@/components/Loading'
import { getCountryIcon } from '../utils'
import { TButton, TIcon, TNumberInput } from '@/components/tmd'
import TokenImg from '@/pages/wallet/components/TokenImg'
import useAlPolicy from './useAlPolicy'
import useAlService from './useAlService'
import toast from '@/components/tmd/toast/Toast'
import useCommonStore from '@/stores/commonStore/hooks/useCommonStore'

export const filterCurrencies = (list: CurrencyItem[]) => {
  const mergedArr = list.reduce((acc: CurrencyItem[], current) => {
    const existing = acc.find((item) => item.currency === current.currency)
    if (existing) {
      existing.payWay?.push(current as CurrencyItem)
    } else {
      acc.push({
        ...current,
        payWay: [current as CurrencyItem]
      })
    }
    return acc
  }, [])
  return mergedArr
}

const BuyView = observer(() => {
  const navigate = useNavigate()
  const { user } = useUserInfo()
  const selectedToken = rampStore.selectedToken
  const chain = selectedToken && getChainByToken(selectedToken)
  const [buyQoute, setBuyQoute] = useState<QuoteRes>()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [amountError, setAmountError] = useState<string>('')
  const [inputAmount, setInputAmount] = useState<string>('')
  const amount = rampStore.buyAmount
  const setAmount = (value: string) => {
    rampStore.setBuyAmount(value)
  }
  const setQuote = rampStore.setBuyQuote
  const { mayKeyboardOpen } = useCommonStore()

  useEffect(() => {
    if (inputAmount) setAmount(inputAmount)
  }, [inputAmount])

  useEffect(() => {
    return () => {
      setInputAmount('')
      setAmountError('')
    }
  }, [])

  const getAlchemyToken = useMutation({
    mutationFn: getAccessToken,
    onSuccess: (data) => {
      localStorage.setItem('alchemy-accessToken', data.accessToken)
      navigate(Routers.ramp.choosePaymentMethod)
      setIsDisabled(false)
    },
    onError: (error) => {
      toast.error('Failed to get access token')
      setIsDisabled(false)
    }
  })

  const { data } = useQuery({
    queryKey: ['getCurrencyListBuy'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!rampStore.buySelectedCurrency,
    queryFn: async () => {
      return await getCurrencyList(QuoteSide.BUY)
    }
  })

  useEffect(() => {
    if (!data) return
    const arr = data.filter((item) =>
      Object.values(SupportPayWay).includes(item.payWayCode as SupportPayWay)
    )
    const mergedArr = filterCurrencies(arr)
    rampStore.setBuyCurrencyList(mergedArr)
    if (!isEmpty(rampStore.buySelectedCurrency)) return
    const cryptoDetail = rampStore.supportCryptoList.find(
      (item) =>
        item.crypto === selectedToken.symbol &&
        item.network === onRampChain[rampStore.selectedToken?.chainId].chain &&
        item.buyEnable
    )
    rampStore.setBuySelectedCurrency({
      ...mergedArr[0],
      cryptoDetail
    })
  }, [data, selectedToken.symbol])

  useEffect(() => {
    if (!selectedToken?.formatted) return
    if (!inputAmount) return
    const minBuy = Math.max(
      Number(rampStore.buySelectedCurrency?.payMin),
      Number(rampStore.buySelectedCurrency?.cryptoDetail?.minPurchaseAmount)
    )
    const maxBuy = Math.min(
      Number(rampStore.buySelectedCurrency?.cryptoDetail?.maxPurchaseAmount),
      Number(rampStore.buySelectedCurrency?.payMax)
    )
    const estAmount = Number(amount)
    if (!amount) {
      setAmountError('')
      return
    } else if (new BigNumber(estAmount).lt(new BigNumber(minBuy))) {
      setAmountError(`Minimum buy amount is ${minBuy}`)
    } else if (new BigNumber(estAmount).gt(new BigNumber(maxBuy))) {
      setAmountError(`Maximum buy amount is ${maxBuy}`)
    } else {
      setAmountError('')
    }
  }, [
    inputAmount,
    amount,
    selectedToken?.formatted,
    rampStore.buySelectedCurrency
  ])

  const getBuyQuote = useMutation<QuoteResponse, unknown, IQuoteParams>({
    mutationFn: (params: IQuoteParams) =>
      getQuote(params).then((res) => res as QuoteResponse),
    onSuccess: (data: QuoteResponse) => {
      if (
        !data.data &&
        data.returnCode !== '10005' &&
        data.returnCode !== '3102'
      ) {
        toast.error(data.returnMsg)
        return
      }
      if (data.data) {
        setQuote(data.data)
        setBuyQoute(data.data)
      }
    }
  })
  const debounceFun = debounce((amount: string) => {
    loadQuote(amount)
  }, 1000)

  const loadQuote = async (amount: string) => {
    getBuyQuote.mutate({
      side: QuoteSide.BUY,
      amount: amount,
      network: onRampChain[rampStore.selectedToken?.chainId].chain,
      crypto: selectedToken?.symbol ?? '',
      fiat: rampStore.buySelectedCurrency?.currency ?? ''
    })
  }

  useEffect(() => {
    rampStore.buySelectedCurrency?.currency && loadQuote(amount)
  }, [rampStore.buySelectedCurrency?.currency])

  const FlagIcon = getCountryIcon(rampStore.buySelectedCurrency.country)

  const { setOpen: setAlOpen, component: alComponent } = useAlPolicy({
    onSkip: () => {
      setAlOpen(false)
    }
  })

  const { setOpen: setSerOpen, component: serComponent } = useAlService({
    onSkip: () => {
      setSerOpen(false)
    }
  })

  const evmETHFlag = useMemo(() => {
    return selectedToken?.symbol.includes('ETH') && !selectedToken?.address
  }, [selectedToken?.symbol, selectedToken?.address])

  const usdAmount = useMemo(() => {
    if (buyQoute && selectedToken) {
      return selectedToken.price * Number(buyQoute.cryptoQuantity)
    }
    return 0
  }, [buyQoute, selectedToken])
  const [bottonShow, setBottomShow] = useState(true)
  useEffect(() => {
    setBottomShow(!mayKeyboardOpen)
  }, [mayKeyboardOpen])

  return (
    <>
      {alComponent}
      {serComponent}
      {!rampStore.buySelectedCurrency.currency ? (
        <div className=" flex h-[300px] flex-col items-center justify-center">
          <LoadingIcon isLoading={!rampStore.buySelectedCurrency} />
        </div>
      ) : (
        <div className="flex h-full flex-col justify-between pt-2">
          <div className="no-scrollbar flex flex-1 flex-col overflow-y-auto pb-6">
            <div className="flex flex-col">
              <div className="mb-3 text-base font-medium text-t1">You pay</div>
              <div
                className={classNames(
                  'flex cursor-pointer justify-between rounded-xl px-4 py-[15px] bg-bg3 text-t1'
                )}
              >
                <TNumberInput
                  fontSize={16}
                  value={inputAmount}
                  onChange={(e) => {
                    setInputAmount(e)
                    debounceFun(e)
                  }}
                  placeholder={'Input amount'}
                  style={{
                    '--placeholder-color': 'var(--text-t4)',
                    color: 'var(--text-t1) !important',
                    '--text-align': 'left'
                  }}
                  className={`  text-base font-medium text-t1`}
                  onFocus={() => {
                    if (
                      window.Telegram.WebApp.platform === 'ios' ||
                      window.Telegram.WebApp.platform === 'android'
                    )
                      setBottomShow(false)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setBottomShow(true)
                    }, 100)
                  }}
                />
                <div
                  onClick={() => {
                    navigate(Routers.ramp.currency())
                  }}
                  className="flex items-center gap-2"
                >
                  <div className="flag-icon size-[22px]">
                    <FlagIcon />
                  </div>
                  <span className="text-base font-medium text-t1">
                    {rampStore.buySelectedCurrency?.currency}
                  </span>
                  <TIcon name="tg_wallet_pull-down1" className="size-4" />
                </div>
              </div>
              {amountError && (
                <div className="mt-2 text-xs  text-red">{amountError}</div>
              )}
            </div>
            <div className="mt-6 flex flex-col">
              <div className="mb-3 text-base font-medium text-t1">
                You receive (estimate)
              </div>
              <div className="flex items-center justify-between gap-7 rounded-[12px] bg-bg3 px-4 py-[15px]">
                <div
                  className={classNames(
                    'w-[70%] text-base font-medium text-t1',
                    (!buyQoute?.cryptoQuantity || !inputAmount) && 'text-t4'
                  )}
                >
                  {inputAmount && buyQoute?.cryptoQuantity
                    ? buyQoute?.cryptoQuantity
                    : '0.00'}
                </div>
                <div className="flex items-center gap-2">
                  <TokenImg
                    symbol={selectedToken?.symbol ?? ''}
                    // image={selectedToken?.image}
                    image={
                      evmETHFlag ? chains.ethereum.icon : selectedToken?.image
                    }
                    chainId={selectedToken?.chainId ?? -1}
                    isNative={selectedToken?.isNative}
                    symbolSize={22}
                    chainSize={12}
                  />
                  <div className="text-base font-medium text-t1">
                    {selectedToken?.symbol}
                  </div>
                </div>
              </div>
            </div>
            {inputAmount && buyQoute && (
              <Countdown
                start={!!buyQoute}
                reloadFun={loadQuote}
                amount={amount}
              />
            )}
            {inputAmount && buyQoute && (
              <Order type={QuoteSide.BUY} data={buyQoute} amount={amount} />
            )}
          </div>
          {bottonShow ? (
            <div className="flex flex-col">
              {!!inputAmount && usdAmount > 300 && (
                <Tip content="The amount exempt from KYC is only $300 per card. Amounts above this amount require KYC verification at Alchemy Pay." />
              )}
              <Checkbox
                className="felx h-[28px] items-center text-t1"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                icon={(checked) =>
                  checked ? (
                    <TIcon
                      name="tg_wallet_finalize-facetiousness"
                      className="text-t1"
                      fontSize="16"
                    />
                  ) : (
                    <div className="mt-[2px] flex size-4 items-center justify-center">
                      <div className="size-[12px] rounded-full border border-t1" />
                    </div>
                  )
                }
              >
                <div className=" text-[11px] font-medium leading-[14px] text-t3">
                  I have read and agree to Alchemy pay’s{' '}
                  <span className="text-blue" onClick={() => setSerOpen(true)}>
                    Term of Service
                  </span>{' '}
                  and{' '}
                  <span className="text-blue" onClick={() => setAlOpen(true)}>
                    Privacy Policy.
                  </span>
                </div>
              </Checkbox>
              <TButton
                size="large"
                disabled={
                  !rampStore.isAlchemySupported ||
                  !buyQoute?.cryptoQuantity ||
                  isDisabled ||
                  !isChecked
                }
                onClick={() => {
                  setIsDisabled(true)
                  getAlchemyToken.mutate(user?.email ?? '')
                  // setShowCheckout(true)
                }}
                color="primary"
                className="mt-4 text-base font-medium"
              >
                Proceed · Buy {selectedToken?.symbol || 'ETH'}
              </TButton>
              <div className="my-[6px] flex items-center justify-center">
                <span className="text-sm text-t3">Powered by</span>
                <img className="h-5" src={alchemyPay} alt="" />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
})

export default BuyView
