import { useEffect, useCallback, useState } from 'react'
import {
  InitData,
  useInitData,
  useWebApp
} from '@vkruglikov/react-telegram-web-app'
import {
  getDefaultWalletAddressApi,
  getOkxWalletAccountApi,
  getSwapFeeAddress,
  getTelegramUserInfoApi,
  heartBeatPost,
  loginJavaApi
} from '@/api'
import { TToast as toast } from '@/components/tmd'
import userStore from '..'
import { errorContents } from '@/config/const'
import { reaction } from 'mobx'
import { ExpreTime } from '../utils'
import { UserType } from '../type'
import tokenStore from '@/stores/tokenStore'
import { Toast } from '@/components/tmd'
import useBiometricManager from '@/hooks/useBiometricManager'
import { useDeviceId } from '@/hooks/useDeviceId'
import * as Sentry from '@sentry/react'
import useMixpanel from '@/hooks/useMixpanel'

const requestToast = () =>
  Toast.show({
    icon: 'loading',
    content: '',
    duration: 0,
    maskClickable: false
  })

const useInitUser = () => {
  const [initDataUnsafe, initData] = useInitData()
  const webApp = useWebApp()
  const { authenticate } = useBiometricManager()
  const { checkIsNewAccount, removeBioKey, getDeviceIdFromToken } =
    useDeviceId()
  const { init } = useMixpanel()

  const getUserInfo = async () => {
    requestToast()
    try {
      const infoResp = await getTelegramUserInfoApi()
      if (infoResp.code !== 10000) {
        toast.warn(errorContents.loginErrors.userInfo)
        userStore.updateAutoLoginAction()
        return
      }
      const AddrResp = await getDefaultWalletAddressApi(infoResp.result.id)
      if (AddrResp.code !== 10000) {
        toast.warn(errorContents.loginErrors.userInfo)
        userStore.updateAutoLoginAction()
        return
      }
      const okxAccountResp = await getOkxWalletAccountApi()
      const userInfo = {
        ...AddrResp.result,
        ...infoResp.result,
        okxAccount: okxAccountResp.result
      }
      userStore.updateUserInfoAction(userInfo)
      userStore.updateUserStateAction({
        ...userStore.userState,
        loginTime: new Date().getTime()
      })
      if (!userStore.pageRoute) {
        userStore.updateRouteAction('/')
      }

      const feeAddr = await getSwapFeeAddress()
      userStore.updateSwapFeeAddress(feeAddr)
      Toast.clear()
    } catch (e) {
      Toast.clear()
      toast.warn(errorContents.serverError)
      userStore.updateAutoLoginAction()
    }
    await heartBeatPost()
  }

  const tgLogin = async (data: InitData) => {
    requestToast()
    try {
      init()
      const resp = await loginJavaApi(data)
      Toast.clear()
      if (resp.code !== 10000) {
        toast.warn(resp?.message || errorContents.loginErrors.wrongInitdata)
        userStore.updateAutoLoginAction()
        return
      }
      const userState = {
        ...resp.result,
        tgId: Number(resp.result.tgId),
        tokenExpired: resp.result.tokenExpiredAt * 1000,
        loginTime:
          userStore.userState.loginTime &&
          Date.now() - userStore.userState.loginTime < ExpreTime
            ? Date.now()
            : 0
      }
      userStore.updateUserStateAction(userState)
      if (
        userStore.userInfo.id &&
        Number(userState.userId) !== userStore.userInfo.id
      ) {
        userStore.updateUserInfoAction({} as UserType)
        tokenStore.tokensReSetActions()
      }
      if (userState.frozen) {
        userStore.updateRouteAction('/login/freeze')
        userStore.updateAutoLoginAction()
        return
      }
      //new user, not set pin
      if (userState.newUser || !userState.setTradePassword) {
        userStore.updateRouteAction('/login/set')
        userStore.updateAutoLoginAction()
        return
      }
      //new mobile phone
      if (!userStore.userState.tgId) {
        userStore.updateRouteAction('/login/verify')
        userStore.updateAutoLoginAction()
        return
      }
      //change user
      if (userStore.userState.tgId !== userState.tgId) {
        userStore.updateRouteAction('/login/verify')
        userStore.updateAutoLoginAction()
        return
      }
      //login time exp
      if (userState.loginTime === 0) {
        bioAutoLogin()
        return
      }
      //go to root /
      userStore.fetchUserInfoAction()
      userStore.updateRouteAction('/')
      userStore.updateAutoLoginAction()
    } catch (e) {
      Toast.clear()
      toast.error(errorContents.serverError)
      console.warn('login error', e)
      userStore.updateAutoLoginAction()
    }
  }

  const bioAutoLogin = () => {
    if (!userStore.biometry.available || !userStore.biometry.token_saved) {
      userStore.updateRouteAction('/login/verify')
      userStore.updateAutoLoginAction()
      return
    }
    init()
    authenticate({
      callback: async (success: boolean, token: string) => {
        if (!success || !token) {
          userStore.updateRouteAction('/login/verify?bio=no')
          userStore.updateAutoLoginAction()
          return
        }
        requestToast()
        const did = getDeviceIdFromToken(token)
        const { isExists } = await checkIsNewAccount(did)
        Toast.clear()
        // authorized
        if (isExists) {
          getUserInfo()
          return
        }
        removeBioKey()
        const biometri = {
          ...userStore.biometry,
          token_saved: false
        }
        userStore.updateBiometryAction(biometri)
        userStore.updateRouteAction('/login/verify')
        userStore.updateAutoLoginAction()
      },
      failCallback: () => {
        userStore.updateRouteAction('/login/verify?bio=no')
        userStore.updateAutoLoginAction()
        return
      }
    })
  }

  /* check tg init data */
  useEffect(() => {
    if (initDataUnsafe && initData) {
      userStore.updateTgAction(initDataUnsafe, initData)

      userStore.loginAction()
      window.Telegram.WebApp.ready()
      const id = initDataUnsafe?.user?.id
      id && Sentry.setUser({ id })
    }
  }, [initDataUnsafe, initData])

  /* tg data with login*/
  const infoFetchRefresh = () => {
    if (userStore.userInfoRefresh !== 0) getUserInfo()
  }

  const loginFetchRefresh = () => {
    const flagUnion = window.location.href.includes('/ramp')
    if (flagUnion) {
      return
    }
    if (!userStore.tgData.query) {
      toast.error('Telegram tomo app init error')
      return
    }
    tgLogin(userStore.tgData.query)
  }

  /* init BiometricManager */
  const bioChangeCallback = useCallback(() => {
    const biometri = {
      access_granted: webApp?.BiometricManager?.isAccessGranted,
      access_requested: webApp?.BiometricManager?.isAccessRequested,
      available: webApp?.BiometricManager?.isBiometricAvailable,
      device_id: webApp?.BiometricManager?.deviceId || '',
      token_saved: webApp?.BiometricManager?.isBiometricTokenSaved,
      platform: window.Telegram.WebApp.platform
    }
    userStore.updateBiometryAction(biometri)
  }, [
    webApp?.BiometricManager?.deviceId,
    webApp?.BiometricManager?.isAccessGranted,
    webApp?.BiometricManager?.isAccessRequested,
    webApp?.BiometricManager?.isBiometricAvailable,
    webApp?.BiometricManager?.isBiometricTokenSaved
  ])
  useEffect(() => {
    if (!webApp?.BiometricManager) return
    webApp?.BiometricManager?.init(function () {})

    window?.Telegram?.WebApp?.onEvent(
      'biometricManagerUpdated',
      bioChangeCallback
    )
    return () => {
      window?.Telegram?.WebApp?.offEvent(
        'biometricManagerUpdated',
        bioChangeCallback
      )
    }
  }, [bioChangeCallback, webApp])

  useEffect(() => {
    const userInfoRefresh = reaction(
      () => userStore.userInfoRefresh,
      infoFetchRefresh,
      { fireImmediately: true }
    )
    const userLoginRefresh = reaction(
      () => userStore.loginState,
      loginFetchRefresh,
      { fireImmediately: true }
    )

    return () => {
      userInfoRefresh()
      userLoginRefresh()
    }
  }, [])
}

export default useInitUser
