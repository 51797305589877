import useTheme from '@/stores/userStore/hooks/useTheme'
import { CheckList, Image, TIcon } from '@/components/tmd'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import { allChains } from '@/proviers/web3Provider/chains'
import '@/components/BaseCheckList.scss'

const SelectChainList = ({
  onConfirm,
  onClose,
  chains,
  current
}: {
  onConfirm: ((chain: IWeb3ChainType) => void) | undefined
  chains?: IWeb3ChainType[]
  current?: IWeb3ChainType
  onClose?: () => void
}) => {
  const chainsList = chains || allChains

  const defaultChain = current || chains[0]

  return (
    <div className="no-scrollbar size-full overflow-y-auto text-xl">
      <CheckList
        activeIcon={
          <TIcon
            className="text-t1"
            name="tg_wallet_finalize-facetiousness"
            fontSize="24"
          />
        }
        defaultValue={defaultChain ? [defaultChain?.name] : []}
        className="tmd-checklist size-full"
      >
        {chainsList?.map((chain) => {
          return (
            <CheckList.Item
              value={chain.name}
              key={chain.name}
              onClick={() => {
                onConfirm?.(chain)
                onClose?.()
              }}
              className=""
            >
              <div className="flex items-center justify-start">
                {chain.id === -1 ? (
                  <TIcon
                    name="tg_wallet_primary-network"
                    className="text-t1"
                    fontSize="32"
                  />
                ) : (
                  <Image
                    lazy
                    className="size-[32px] rounded-full"
                    src={chain.icon}
                    alt="chain.name"
                  />
                )}
                <span className="ml-3 text-base text-t1">{chain.name}</span>
              </div>
            </CheckList.Item>
          )
        })}
      </CheckList>
    </div>
  )
}

export default SelectChainList
