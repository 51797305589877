import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routers } from '@/router'
import TokenChainIcon from '@/pages/market/components/TokenChainIcon'
import { alchemyPay, arrowSvgs, rampEmpty, usdSvg } from '@/assets'
import { Button, Input } from 'antd-mobile'
import classNames from 'classnames'
import Countdown from './Countdown'
import Order from './Order'
import { observer } from 'mobx-react-lite'
import AdaptiveNumber, { NumberType } from '@/components/AdaptiveNumber'
import { getChainByToken } from '@/stores/walletStore/utils'
import rampStore from '@/stores/rampStore'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getAccessToken, getCurrencyList, getQuote } from '@/api/ramp'
import {
  CurrencyItem,
  QuoteSide,
  QuoteRes,
  SupportPayWay,
  IQuoteParams,
  QuoteResponse
} from '@/api/ramp/type'
import { filterCurrencies } from './BuyView'
import useUserInfo from '@/stores/userStore/hooks/useUserStore'
import { isEmpty } from '@/utils/helper'
import { marketChain } from '@/proviers/web3Provider/chains'
import BigNumber from 'bignumber.js'
import { debounce } from '@/utils/debounce'
import { TNoResult } from '@/components/tmd'
import toast from '@/components/tmd/toast/Toast'
import useTheme from '@/stores/userStore/hooks/useTheme'

export const SellView: React.FC = observer(() => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const { user } = useUserInfo()
  const selectedToken = rampStore.selectedToken
  const [amount, setAmount] = useState<string>('')
  const [amountError, setAmountError] = useState<string>('')
  const [sellQoute, setSellQoute] = useState<QuoteRes>()
  const chain = selectedToken && getChainByToken(selectedToken)
  const chainImg = chain?.icon
  const setQuote = rampStore.setSellQuote

  // const getAlchemyToken = useMutation({
  //   mutationFn: getAccessToken,
  //   onSuccess: (data) => {
  //     localStorage.setItem('alchemy-accessToken', data.accessToken)
  //     navigate(Routers.ramp.currency())
  //   },
  //   onError: (error) => {
  //     console.log(error)
  //   }
  // })
  // const { data: sellData } = useQuery({
  //   queryKey: ['getCurrencyListSell'],
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  //   enabled: !!rampStore.sellSelectedCurrency,
  //   queryFn: async () => {
  //     return await getCurrencyList(QuoteSide.SELL)
  //   }
  // })

  const emptyIcon = useMemo(() => {
    return theme == 'light'
      ? '/assets/imgs/noresult/light.svg'
      : '/assets/imgs/noresult/dark.svg'
  }, [theme])

  // useEffect(() => {
  //   if (!sellData) return
  //   const mergedArr = filterCurrencies(sellData)
  //   rampStore.setSellCurrencyList(mergedArr)
  //   if (!isEmpty(rampStore.sellSelectedCurrency)) return
  //   const cryptoDetail = rampStore.supportCryptoList.find(
  //     (item) =>
  //       item.crypto === selectedToken.symbol &&
  //       item.network === marketChain[rampStore.selectedToken?.chainId].chain &&
  //       item.sellEnable
  //   )
  //   rampStore.setSellSelectedCurrency({
  //     ...mergedArr[0],
  //     cryptoDetail
  //   })
  // }, [selectedToken.symbol, sellData])

  // useEffect(() => {
  //   if (!selectedToken?.formatted) return
  //   const balance = Number(selectedToken.formatted) ?? 0
  //   const minSell = Number(
  //     rampStore.sellSelectedCurrency?.cryptoDetail?.minSellAmount
  //   )
  //   const maxSell = Number(
  //     rampStore.sellSelectedCurrency?.cryptoDetail?.maxSellAmount
  //   )

  //   const inputAmount = Number(amount)
  //   if (!amount) {
  //     setAmountError('')
  //     return
  //   } else if (new BigNumber(inputAmount).lt(new BigNumber(minSell))) {
  //     setAmountError(`Minimum sell amount is ${minSell}`)
  //   } else if (inputAmount > balance) {
  //     setAmountError('Insufficient balance')
  //   } else if (new BigNumber(inputAmount).gt(new BigNumber(maxSell))) {
  //     setAmountError(`Maximum sell amount is ${maxSell}`)
  //   } else {
  //     setAmountError('')
  //   }
  // }, [amount, selectedToken?.formatted, rampStore.sellSelectedCurrency])

  // const getSellQuote = useMutation<QuoteResponse, unknown, IQuoteParams>({
  //   mutationFn: (params: IQuoteParams) =>
  //     getQuote(params).then((res) => res as QuoteResponse),
  //   onSuccess: (data: QuoteResponse) => {
  //     if (!data.data && data.returnCode === '99999') {
  //       toast.error(data.returnMsg)
  //       return
  //     }
  //     if (data.data) {
  //       setQuote(data.data)
  //       setSellQoute(data.data)
  //     }
  //   }
  // })
  // const debounceFun = debounce((amount: string) => {
  //   loadQuote(amount)
  // }, 1000)

  // const loadQuote = async (amount: string) => {
  //   getSellQuote.mutate({
  //     side: QuoteSide.SELL,
  //     amount: amount,
  //     network: marketChain[rampStore.selectedToken?.chainId].chain,
  //     crypto: selectedToken?.symbol ?? '',
  //     fiat: rampStore.sellSelectedCurrency?.currency ?? ''
  //   })
  // }

  // useEffect(() => {
  //   rampStore.sellSelectedCurrency?.currency && loadQuote(amount)
  // }, [rampStore.sellSelectedCurrency?.currency])

  return (
    <div className="flex h-full flex-col justify-between pt-6">
      <div className="mt-10 flex flex-col items-center">
        <img src={emptyIcon} alt="empty" />
        <div className=" mt-4 text-center text-[20px] font-semibold text-t1">
          This feature is currently unavailable.
        </div>
        <div className=" mt-2 text-center text-sm text-t3">
          This feature is currently unavailable. Please sell your tokens on
          other supported exchanges(e.g., Binance, OKX, Coinbase, Bybit,
          Bitget...).
        </div>
      </div>
      <div className="my-[6px] flex items-center justify-center">
        <span className="text-sm text-t3">Powered by</span>
        <img className="h-5" src={alchemyPay} alt="" />
      </div>

      {/* <div className="no-scrollbar flex flex-1 flex-col overflow-y-auto ">
        <div className="flex flex-col gap-[10px]">
          <div className="flex justify-between text-sm font-medium text-text3">
            <span>You sell</span>
            <span>
              Balance:
              <AdaptiveNumber
                type={NumberType.BALANCE}
                value={selectedToken?.formatted ?? 0}
              />
            </span>
          </div>

          <div
            className={classNames(
              amountError ? 'border-primary' : 'border-neutral4',
              'flex cursor-pointer justify-between rounded-xl border  px-4 py-3 '
            )}
          >
            <Input
              value={amount}
              onChange={(e) => {
                setAmount(e)
                debounceFun(e)
              }}
              placeholder={'Input amount'}
              className={`w-[65%] text-base font-medium`}
            />

            <div className="flex items-center gap-[10px] border-l border-gray-100 pl-[52px]">
              <TokenChainIcon
                tokenIcon={selectedToken?.image}
                tokenSymbol={selectedToken?.symbol ?? ''}
                chainIcon={selectedToken?.chainId ? chainImg : undefined}
                isNative={selectedToken?.isNative}
                size={32}
              />
              <div className="text-xl font-semibold text-title">
                {selectedToken?.symbol}
              </div>
            </div>
          </div>
          {amountError && (
            <div className="text-xs text-primary">{amountError}</div>
          )}
        </div>

        <div className="mt-6 flex flex-col gap-[10px]">
          <span className="text-sm font-medium text-text3">
            You receive (estimate)
          </span>
          <div className="flex items-center justify-between rounded-xl border border-neutral4 px-4 py-3">
            <span className="text-base">
              {new BigNumber(sellQoute?.fiatQuantity ?? 0)
                .minus(new BigNumber(sellQoute?.rampFee ?? 0))
                .toString()}
            </span>
            <div
              onClick={() => {
                getAlchemyToken.mutate(user?.email ?? '')
              }}
              className="flex items-center gap-[10px] border-l border-gray-100 pl-7"
            >
              <span className="text-xl font-semibold text-title">
                {rampStore.sellSelectedCurrency?.currency}
              </span>
              <img
                className="w-[16px] rotate-90"
                src={arrowSvgs.rightV2Svg}
                alt=""
              />
            </div>
          </div>
        </div>

        {sellQoute && (
          <Countdown
            start={!!sellQoute}
            reloadFun={loadQuote}
            amount={amount}
          />
        )}
        {sellQoute && (
          <Order data={sellQoute} type={QuoteSide.SELL} amount={amount} />
        )}
      </div>
      <div className="flex flex-col gap-4">
        <Button
          size="large"
          // disabled={!rampStore.isAlchemySupported || !sellQoute?.cryptoQuantity}
          onClick={() => {
            const code = rampStore.sellSelectedCurrency.payWayCode
            rampStore.setSelectPayWayCode(code as SupportPayWay)
            navigate(Routers.ramp.cardDetails)
          }}
          color="primary"
          className="h-12 rounded-lg text-xl font-medium"
        >
          Proceed · Sell ETH →
        </Button>
        <div className="flex items-center justify-center gap-5">
          <span className="text-lg text-text3">Powered by</span>
          <img className="h-10" src={alchemyPay} alt="" />
        </div>
      </div> */}
    </div>
  )
})
