import { TIcon, useTranslation } from '@/components/tmd'
import SelectChainList from './index'
import usePopup from '@/hooks/popup/usePopup'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'

const useSelectChains = ({
  onConfirm,
  afterClose,
  onClose,
  chains,
  current
}: {
  onConfirm?: ((chain: IWeb3ChainType) => void) | undefined
  onClose?: () => void
  afterClose?: () => void
  chains?: IWeb3ChainType[]
  current?: IWeb3ChainType
}) => {
  const { t } = useTranslation()
  return usePopup({
    title: t('tg_wallet_account_chain_list.chain_list_title'),
    afterClose,
    showCloseButton: true,
    maskGesture: true,
    closeIcon: <TIcon name="tg_wallet_disable text-t1" fontSize="20" />,
    fullscreen: true,
    contentClassName: '!pt-[8px] overflow-hidden',
    iconClassName: '!bg-bg5',
    bodyStyle: {
      '--adm-color-background': 'var(--background-BG4)'
    },
    content: (
      <SelectChainList
        chains={chains}
        onConfirm={onConfirm}
        onClose={onClose}
        current={current}
      />
    )
  })
}

export default useSelectChains
