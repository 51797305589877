import { FeeMode } from '@/components/FeeSelect'
import { makeAutoObservable } from 'mobx'

class CommonStore {
  balanceFetchSwitch: boolean = false
  viewHeight: number = 0
  staticHeight: number = 0
  mayKeyboardOpen: boolean = false
  feeMode: FeeMode = FeeMode.AVERAGE

  constructor() {
    makeAutoObservable(this)
  }

  feeModeActions(feeMode: FeeMode) {
    this.feeMode = feeMode
  }

  updatebalanceSwitch(flag: boolean) {
    this.balanceFetchSwitch = flag
  }
  updateViewHeightChange(num: number) {
    // console.log('this.viewHeight >= num', this.viewHeight, num)
    this.mayKeyboardOpen = this.viewHeight >= num
    this.viewHeight = num
    if (!this.mayKeyboardOpen) window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  updateStaticViewHeight(num: number) {
    this.staticHeight = num
    this.viewHeight = num
  }
}

const commonStore = new CommonStore()
export default commonStore
