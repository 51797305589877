import { ChainItemWithSwitch } from '@/components/ChainItem'
import {
  TSearch as Search,
  TIcon,
  TNoResult,
  useTranslation
} from '@/components/tmd'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import useSelectChains from '../SelectChains/useSelectChains'
import chains, { allChains } from '@/proviers/web3Provider/chains'
import { IWeb3ChainType, IWeb3Type } from '@/proviers/web3Provider/type'
import { LoadingIcon } from '@/components/Loading'
import classnames from 'classnames'

interface SelectSendTokenPropsType {
  title: string
  tokens: AssetsToken[]
  onSearch: (val: string) => void
  onClick: (token: AssetsToken) => void
  showSelectChain?: boolean
  onSelectChain?: (chain: IWeb3ChainType) => void
  isLoading: boolean
  curChain?: IWeb3ChainType | undefined
  emptyTips?: string
  classNames?: string
}
export default function SelectToken({
  title,
  tokens,
  onSearch,
  onClick,
  showSelectChain = true,
  onSelectChain,
  isLoading = false,
  curChain,
  emptyTips,
  classNames = ''
}: SelectSendTokenPropsType) {
  const { t } = useTranslation()
  const { setOpen, component } = useSelectChains({
    onConfirm: (chain) => {
      onSelectChain?.(chain)
    },
    onClose: () => {
      setOpen(false)
    },
    chains: allChains,
    current: curChain
  })
  return (
    <div className={classnames('flex h-full flex-col', classNames)}>
      {component}
      <div className="select-title ml-[20px] mt-4">
        <span className="text-h3 font-semibold  text-t1">{title}</span>
        {showSelectChain ? (
          <span
            className="absolute right-[20px] rounded-full bg-bg3 p-[8px] pb-[4px] text-t1"
            onClick={() => setOpen(true)}
          >
            {curChain?.id === chains?.allChain.id ? (
              <TIcon
                name="tg_wallet_primary-network"
                className="text-t1"
                fontSize="22"
              />
            ) : (
              <img
                className="mb-[4px] size-[20px] rounded-full"
                src={curChain?.icon || ''}
                alt="Chain"
              />
            )}
            {/* <TIcon fontSize="20" name="tg_wallet_primary-network" /> */}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div className="select-search mx-[20px] mt-4">
        <Search
          type={'change'}
          onChange={(v) => {
            onSearch(v)
          }}
          placeholder={t('tg_wallet_search.search_token')}
        />
      </div>
      <div className="no-scrollbar flex-1 overflow-scroll">
        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingIcon isLoading={isLoading} />
          </div>
        ) : (
          <div className="select-token-list mx-6 mt-[12px] h-full">
            {tokens.length > 0 ? (
              tokens.map((token) => (
                <ChainItemWithSwitch
                  key={token.id}
                  token={token}
                  icon={token.image}
                  symbol={token.symbol}
                  price={token.price}
                  holderNum={Number(token.formatted)}
                  onClick={() => onClick(token)}
                />
              ))
            ) : (
              <div className="-mt-[68px] h-full">
                <TNoResult
                  className=" text-sm text-t3"
                  emptyText={emptyTips ?? 'No search result'}
                  type="list"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
